import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Log } from 'oidc-client';
import { AuthService } from 'app/views/auth/auth.service';
import { CookieService } from 'ngx-cookie';
import { map } from 'rxjs/operators';
import { Profile } from 'app/shared/profile/_models/profile.model';
import { ProfileService } from 'app/shared/profile/profile.service';

@Component({
  selector: 'app-silent',
  providers: [CookieService],
  templateUrl: 'silent.component.html',
  styleUrls: ['silent.component.scss']
})
export class SilentComponent implements OnInit {
  cookies: Object;

  constructor(private location: Location,
    private service: AuthService,
    private _profileService: ProfileService
  ) {}

  ngOnInit() {
    Log.logger = console;
    var self = this;

    self.service.mgr.signinSilentCallback().then(function (user) {
      if (user == null) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("error").innerText = "No sign-in request pending.";
      }
      else {
        // Compute check if Profile exists for the first time here.
        var authenticatedUser = user;
        var userProfile = authenticatedUser.profile;
        var subjectId = userProfile.sub;
        if (subjectId) {
          self.onProfileCheck(subjectId);
        }
      }
    }).catch(function (er) {
      console.log(er);
      console.log(er.message);
    });
  }

  onProfileCheck(subjectId: string) {
    this._profileService.getProfileBySubjectIdQuery(subjectId)
      .pipe(map(result => {
        var item = (<any>result);
        return (<any>item) as Profile;
      })
      ).subscribe((item: any) => {

        if (item.id) {
          this.service.setApplicationProfileUser(item);
        }
      });
  }
}
