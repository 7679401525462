import { environment } from "environments/environment";

export class AppSettings {
    public static readonly API_ENDPOINT = environment.API_ENDPOINT;
    // public static readonly NOTIFICATIONS_API_ENDPOINT = environment.NOTIFICATIONS_API_ENDPOINT;

    public static readonly AUTHORITY_ENDPOINT = environment.AUTHORITY_ENDPOINT;
    public static readonly REDIRECT_URI = environment.REDIRECT_URI;

    public static readonly SILENT_REDIRECT_URI = environment.SILENT_REDIRECT_URI;
    public static readonly POST_LOGOUT_REDIRECT_URI = environment.POST_LOGOUT_REDIRECT_URI;

    public static readonly PORTAL_URI = environment.PORTAL_URI;
  }