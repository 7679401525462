import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Response } from "@angular/http";

import { CommunicationHistoryService } from "../../../shared/services/communication-history.service";
import { ContactService } from "../../../shared/services/contact.service";
import { ToastService } from "../../../shared/services/toast.service";
import { OpportunityService } from "../../../shared/services/opportunity.service";
import { ComplaintService } from "../../../shared/services/complaint.service";

import { Contact } from "../../../shared/models/contact.model";

import { SelectItem, AutoComplete } from "primeng/primeng";
import { Opportunity } from "app/views/shared/models/opportunity.model";
import { Complaint } from 'app/views/shared/models/complaint.model';

@Component({
  selector: 'app-communication-history-header',
  templateUrl: './communication-history-header.component.html',
  styleUrls: ['./communication-history-header.component.scss']
})
export class CommunicationHistoryHeaderComponent implements OnInit {
  contactsOptions: SelectItem[] = [];        // - Stores Contact Dropdown Options
  selectedContactOption: any = {};           // - Stores the selected dropdown option for contacts which can be 'all' or the id of a contact
  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;

  opportunitiesOptions: SelectItem[] = [];    // - Stores Opportunity Dropdown Options
  complaintsOptions: SelectItem[] = [];    // - Stores Issue Dropdown Options
  selectedOpportunityOption: any;             // - Stores the selected dropdown option for opportunities which can be 'all' or the id of an opportunity
  selectedComplaintOption: any;               // - Stores the selected dropdown option for complaint which can be 'all' or the id of an opportunity

  /**
   * 0 - Opportunity
   * 1 - Issue
   */
  selectedLogType: number = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private opportunityService: OpportunityService,
    private complaintService: ComplaintService,
    private toastService: ToastService
  ) { }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  onContactChange() {
    this.router.navigate(['/contacts/' + this.selectedContactOption.value + '/communication-history']);
    this.onInitOpportunitiesOptions();
  }

  onOpportunityChange() {
    this.router.navigate(['/contacts/' + this.selectedContactOption.value + '/opportunity/' + this.selectedOpportunityOption + '/communication-history/']);
  }

  onComplaintChange() {
    this.router.navigate(['/contacts/' + this.selectedContactOption.value + '/complaint/' + this.selectedComplaintOption + '/communication-history/']);
  }

  onInitOpportunitiesOptions() {
    this.opportunitiesOptions = []; // - Reset opportunity options list
    this.opportunitiesOptions.push({ label: 'General', value: 'general' }); // - Default value that shows all comm. logs of a contact that dont have an opportunityId
    this.onGetContactOpportunities(this.selectedContactOption.value);
  }

  onInitComplaintsOptions() {
    this.complaintsOptions = []; // - Reset opportunity options list
    this.complaintsOptions.push({ label: 'Select Complaint', value: 'general' }); // - Default value that shows all comm. logs of a contact that dont have an opportunityId
    this.onGetContactComplaints(this.selectedContactOption.value);
  }


  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  ngOnInit() {


    this.contactsOptions = [...this.contactsOptions, { label: 'All', value: 'all' }]; // - Default value that shows all comm. logs

    this.route.params.subscribe(
      (params: Params) => {
        const contactId = params['contactId'];

        if (contactId != 'all') {
          this.selectedContactOption.value = contactId;
        }
        else {
          this.selectedContactOption.label = "All";
          this.selectedContactOption.value = 'all';
        }
      }
    );


    this.route.params.subscribe(
      (params: Params) => {
        const complaintId = params['complaintId'];
        this.onInitComplaintsOptions();
        if (complaintId && complaintId != 'general'){
          this.selectedLogType = 1;
        }
      }
    );


    this.route.params.subscribe(
      (params: Params) => {
        const opportunityId = params['opportunityId'];
        this.onInitOpportunitiesOptions();
        if (opportunityId && opportunityId != 'general'){
          this.selectedLogType = 0;
        }

      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: Contact[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];
        retrievedContactsOptions.push({ label: 'All', value: 'all' });

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {

          let organization = cT["organization"];

          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.homePhoneNumber ? labelText += " / Tel: " + cT.homePhoneNumber : "";
          cT.mobilePhoneNumber ? labelText += " / Mob: " + cT.mobilePhoneNumber : "";
          cT.email ? labelText += " / Email: " + cT.email : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.contactsOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onGetContactOpportunities(contactId: number) {
    this.opportunityService.getOpportunitiesByAssignedContactId(contactId).subscribe(
      (opportunities: Opportunity[]) => {
        // Store retrieved opportunities in the opportunitiesOptions select list
        opportunities.map(o => {
          let labelText = o.name;
          labelText += " / Type: " + o.opportunityType.name;

          this.opportunitiesOptions.push(
            {
              label: labelText,
              value: o.id
            }
          )
        });

        this.route.params.subscribe(
          (params: Params) => {
            const opportunityId = params['opportunityId'];

            if (opportunityId != 'general'){
              this.selectedOpportunityOption = opportunityId;
            }
            else
              this.selectedOpportunityOption = 'general';
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunities", error);
      }
    );
  }

  onGetContactComplaints(contactId: number) {
    this.complaintService.getComplaintsByAssignedContactId(contactId).subscribe(
      (complaints: Complaint[]) => {
        // Store retrieved complaints in the complaintsOptions select list
        complaints.map(c => {
          let labelText = c.code;
          //let labelDate = this.transformDate(c.reportedDate);
          labelText += " / " + c.complaintStatus.name+ " / " + c.complaintType.name ;//+ " / " + labelDate;

          this.complaintsOptions.push(
            {
              label: labelText,
              value: c.id
            }
          )
        });

        this.route.params.subscribe(
          (params: Params) => {
            const complaintId = params['complaintId'];

            if (complaintId != 'general')
            {
              this.selectedComplaintOption = complaintId;
            }
            else
              this.selectedComplaintOption = 'general';
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving complaints", error);
      }
    );
  }

  // transformDate(date){
  //   return (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();
  // }


}
