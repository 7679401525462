import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { OpportunitiesComponent } from "./opportunities.component";
import { OpportunityListComponent } from "./opportunity-list/opportunity-list.component";
import { OpportunityManageComponent } from "./opportunity-manage/opportunity-manage.component";
import { OpportunityQuoteManageComponent } from './opportunity-quotes/opportunity-quote-manage/opportunity-quote-manage.component';

const opportunitiesRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'opportunities', component: OpportunitiesComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: OpportunityListComponent },
          { path: ':id/edit', component: OpportunityManageComponent },
          { path: 'new', component: OpportunityManageComponent },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(opportunitiesRoutes)],
  exports: [RouterModule]
})
export class OpportunitiesRoutingModule { }
