import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from "@angular/http";
import 'jquery-slimscroll';

import { AuthService } from "../../../views/auth/auth.service";
import { TaskService } from "../../../views/shared/services/task.service";
import { ToastService } from "../../../views/shared/services/toast.service";

import { CrmUser } from "../../../views/shared/models/user.model";
import { Task } from "../../../views/shared/models/task.model";
import { PrepopulatedContactTypes } from "app.constant";
import { DeveloperModeHelper } from 'app/views/shared/developer.mode.service';

declare var jQuery: any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html'
})

export class NavigationComponent implements OnDestroy {
  user: CrmUser;
  incompleteTasksCount = 0; // - Number of incomplete tasks (exlcuding today's and late tasks) (Blue)
  todaysTasksCount = 0;     // - Today's incomplete tasks (Yellow)
  lateTasksCount = 0;       // - Tasks that passed their scheduled date and still incomplete (Red)

  leadTypeName = PrepopulatedContactTypes.Lead;

  showDeveloperLabel = false;
  showDeveloperModeLabel = false;
  isDeveloper$ = null;
  developerMode$ = null;

  constructor(
    public authService: AuthService,
    public taskService: TaskService,
    private toastService: ToastService,
    private developer: DeveloperModeHelper,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isDeveloper$ = this.developer.isDeveloper.subscribe((isDeveloper) => {
  this.showDeveloperLabel = isDeveloper;
});

this.developerMode$ = this.developer.isDeveloperModeEnabled.subscribe((isDeveloperModeEnabled) => {
  this.showDeveloperModeLabel = isDeveloperModeEnabled;
});

    this.user = this.authService.applicationProfileUser() as CrmUser;
    this.onGetIncompleteTasksCount();

    // Listen to changes made to tasks
    this.taskService.taskCounterChanged.subscribe(
      () => {
        this.onGetIncompleteTasksCount();
      }
    );
  }
  ngOnDestroy() : void {
if(this.isDeveloper$) {
  this.isDeveloper$.unsubscribe();
}
  }

  onGetIncompleteTasksCount() {
    if (this.authService.isAdmin()) {
      this.taskService.getIncompleteTasks().subscribe(
        (incompleteTasks: Task[]) => {
          this.setCountValues(incompleteTasks);
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    }
    else {
      this.taskService.getIncompleteTasksByUserId(this.authService.applicationProfileUser().id).subscribe(
        (incompleteTasks: Task[]) => {
          this.setCountValues(incompleteTasks);
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    }
  }

  setCountValues(incompleteTasks: Task[]) {
    this.incompleteTasksCount = incompleteTasks.length;
    this.lateTasksCount = incompleteTasks.filter(t => this.taskService.isTaskLate(t)).length;
    this.todaysTasksCount = incompleteTasks.filter(t => this.taskService.isTaskForToday(t)).length;
    this.incompleteTasksCount -= (this.lateTasksCount + this.todaysTasksCount);
  }

  onLogOut() {
    this.authService.logOut();
  }

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();

    if (jQuery("body").hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }
}
