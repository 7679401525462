import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";

import { OpportunitiesRoutingModule } from "./opportunities-routing.module";

import { SharedModule } from "../shared/shared.module";
import { CalendarModule, DropdownModule, SliderModule } from "primeng/primeng";
import { OpportunityFilesModule } from "./opportunity-files/opportunity-files.module";

import { OpportunityLostTypesModule } from './opportunity-lost-types/opportunity-lost-types.module';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { OpportunitiesComponent } from './opportunities.component';
import { OpportunityListComponent } from './opportunity-list/opportunity-list.component';
import { OpportunityManageComponent } from './opportunity-manage/opportunity-manage.component';
import { OpportunityQuotesModule } from './opportunity-quotes/opportunity-quotes.module';

@NgModule({
  declarations: [
    OpportunitiesComponent,
    OpportunityListComponent,
    OpportunityManageComponent
  ],
  imports: [
    SharedModule,
    OpportunitiesRoutingModule,
    OpportunityFilesModule,
    OpportunityLostTypesModule,
    ReactiveFormsModule,
    DropdownModule,
    SliderModule,
    CollapseModule,
    CalendarModule,
    OpportunityQuotesModule
  ]
})
export class OpportunitiesModule { }
