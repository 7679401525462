import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { ContactService } from '../../shared/services/contact.service';
import { SmsWarriorsService } from '../../shared/services/sms-warriors.service';
import { MessageTemplateService } from '../../shared/services/message-template.service';
import { SmsWarriorsProfileService } from '../../shared/services/sms-warriors-profile.service';
import { ToastService } from '../../shared/services/toast.service';
import { GroupService } from '../../shared/services/group.service';
import { CommunicationHistoryService } from '../../shared/services/communication-history.service';

import { SmsWarriorsResponse } from '../../shared/models/sms-warriors-response.model';
import { Contact } from '../../shared/models/contact.model';

import { SelectItem, ConfirmationService, AutoComplete } from 'primeng/primeng';

import { CustomFormBuilder } from '../../shared/classes/CustomFormBuilder';
import { SmsWarriors, CommunicationDirection, CommunicationLogTypeNames } from 'app.constant';
import { MessageTemplate } from '../../shared/models/message-template.model';
import { Group } from '../../shared/models/group.model';
import { SmsWarriorsProfile } from '../../shared/models/sms-warriors-profile.model';
import { CommunicationLog } from '../../shared/models/communication-log.model';

@Component({
  selector: 'app-message-centre-sms',
  templateUrl: './message-centre-sms.component.html',
  styleUrls: ['./message-centre-sms.component.scss']
})
export class MessageCentreSmsComponent implements OnInit {
  smsForm: FormGroup;
  isFormInit: boolean = false;

  fromOptions: SelectItem[] = [];
  toOptions: SelectItem[] = [];
  contactGroupsOptions: SelectItem[] = [];
  countryCodeOptions: SelectItem[] = [];

  templateOptions: SelectItem[] = [];
  selectedTemplateOption: string;

  messageMaxLength: number = 720;

  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;

  constructor(
    private formBuilder: CustomFormBuilder,
    private smsWarriorsService: SmsWarriorsService,
    private authService: AuthService,
    private contactService: ContactService,
    private groupService: GroupService,
    private messageTemplateService: MessageTemplateService,
    private smsWarriorsProfileService: SmsWarriorsProfileService,
    private communicationHistoryService: CommunicationHistoryService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: Contact[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.homePhoneNumber ? labelText += " / Tel: " + cT.homePhoneNumber : "";
          cT.mobilePhoneNumber ? labelText += " / Mob: " + cT.mobilePhoneNumber : "";
          cT.email ? labelText += " / Email: " + cT.email : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT
            }
          );
        });

        this.toOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  initForm() {
    this.smsForm = this.formBuilder.group({
      from: this.formBuilder.control(null, [Validators.required]),
      to: this.formBuilder.control(null),
      toGroups: this.formBuilder.control(null),
      countryCode: this.formBuilder.control(null, [Validators.required]),
      message: this.formBuilder.control(null, [Validators.required, Validators.maxLength(this.messageMaxLength)])
    });

    this.onGetContactGroups();
    this.onGetFromOptions();
    this.onGetCountryCodes();
    this.onGetMessageTemplates();

    this.isFormInit = true;
  }

  onGetContactGroups() {
    this.groupService.getGroups().subscribe(
      (groups: Group[]) => {
        groups.map(g => {
          this.contactGroupsOptions.push({ label: g.name, value: g });
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact groups", error);
      }
    );
  }

  onGetFromOptions() {
    this.smsWarriorsProfileService.getSmsWarriorProfiles().subscribe(
      (profiles: SmsWarriorsProfile[]) => {
        profiles.map(p => {
          this.fromOptions.push({ label: p.companyName, value: p });
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving sms profiles", error);
      }
    );
  }

  onGetMessageTemplates() {
    this.messageTemplateService.getMessageTemplates().subscribe(
      (messageTemplates: MessageTemplate[]) => {
        this.templateOptions = [];

        messageTemplates.map(mT => {
          this.templateOptions.push({ label: mT.name, value: mT.message });
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving templates", error);
      }
    );
  }

  onGetCountryCodes() {
    let countryCodes = SmsWarriors.CountryCodes;

    countryCodes.map(c => {
      this.countryCodeOptions.push({ label: `${c.country} (${c.code})`, value: c.code });
    });
  }

  onTemplateChange($event) {
    let selectedValue = $event.value;
    let messageValue = this.smsForm.controls['message'].value;

    if (messageValue && messageValue.trim().length > 0) {
      this.confirmationService.confirm({
        header: 'Confirm',
        message: 'The template will overwrite your message. Would you like to continue?',
        accept: () => {
          this.smsForm.patchValue({
            message: selectedValue
          });
        },
        reject: () => {
          this.selectedTemplateOption = null;
        }
      });
    }
    else {
      this.smsForm.patchValue({
        message: selectedValue
      });
    }
  }

  onSubmit() {
    this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to send this message to the selected contacts?',
      accept: () => {
        let smsMessage = this.formBuilder.sanitizeFormValues(this.smsForm).value;

        let allContactIds = []; // - Ids of contacts that will receive this SMS

        let toIndividualContactIds = [];
        if (smsMessage.to) {
          // - Get ContactIds from the To Individual field
          toIndividualContactIds = smsMessage.to.map(c => c.value.id) as Array<number>;
        }

        let toGroupContactIds = [];
        if (smsMessage.toGroups) {
          // - Get ContactIds from the To Contact Group field
          toGroupContactIds = smsMessage.toGroups.map(
            c => c.contactGroups.map(cG => cG.contactId) as Array<number>) as Array<Array<number>>;

          // - Get ContactIds from ContactGroup objects for each Group
          toGroupContactIds.map(
            (contactIds) => {
              contactIds.map(cId => allContactIds.push(cId))
            }
          );
        }

        allContactIds = allContactIds.concat(toIndividualContactIds);

        if (allContactIds.length > 0) {
          let smsProfile = smsMessage.from as SmsWarriorsProfile;
          let countryCode = smsMessage.countryCode;
          // let contactsArr = smsMessage.to.map(t => t.value) as Array<Contact>;

          this.onSendSms(smsProfile.apiKey, countryCode, allContactIds, smsProfile.companyName, smsMessage.message, 0);
        }
        else {
          this.toastService.createWarningMessage("No recipients", "Please select at least one SMS recipient.");          
        }
      }
    });
  }

  onSendAndSave() {
    this.onSubmit();

    let smsMessage = this.formBuilder.sanitizeFormValues(this.smsForm).value;

    let generatedName = smsMessage.message as string;
    generatedName = generatedName.substring(0, 50);
    if (generatedName.length > 50)
      generatedName += "...";

    let messageTemplate: any = {
      message: smsMessage.message,
      name: generatedName,
      createdByUserAccountId: this.authService.applicationProfileUser().id,
    };

    this.messageTemplateService.addMessageTemplate(messageTemplate).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The template has been created.");

        this.onGetMessageTemplates(); // - Get template options again
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding template", error);
      }
    );
  }

  onSendSms(apiKey: string, countryCode: string, contactIds: number[], senderId: string, msgBody: string, notification: number) {
    this.smsWarriorsService.requestSMS(apiKey, countryCode, contactIds, this.authService.applicationProfileUser().id, senderId, msgBody, notification).subscribe(
      (response: SmsWarriorsResponse) => {
        this.toastService.createSuccessMessage("Success", "The SMS messages have been queued");
        console.log("Response from sms:", response);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error sending SMS", error);
        console.log("Err send sms:", error);
      }
    );
  }
}
