import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { Response, Http } from "@angular/http";

import { AuthHttp } from "angular2-jwt/angular2-jwt";
import { AuthService } from "../../auth/auth.service";
import { Email } from "../models/email.model";
import { Task } from "../models/task.model";
import { CrmUser } from "../models/user.model";
import { Contact } from "../models/contact.model";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class EmailService {
  private emailsUrl: string = AppSettings.API_ENDPOINT + 'api/email/';

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  sendEmail(email: Email): Observable<Response> {
    return this.http.post<Response>(
      this.emailsUrl,
      email
    );
  }

  sendEmailWithProfile(email: Email, profileId: number): Observable<Response> {
    return this.http.post<Response>(
      this.emailsUrl + "profileId/" + profileId,
      email
    );
  }

  createUserTaskEmail(task: Task, sender: CrmUser, receiver: CrmUser, calendarDuration: number) {
    let subject = sender.fullName;
    subject += task.updatedByUserAccountId ?
      " has updated a task that you are assigned to" :
      " has assigned you to a new task";

    let intro = "Hello " + receiver.fullName + "<br/><br/>";
    intro += task.updatedByUserAccountId ?
      "The following task has been updated:" :
      "You have been assigned to the following task:";
    intro += "<br/><br/>";

    const createdOnDate = new Date(task.createdOn).toLocaleString("en-GB");
    const updatedOnDate = new Date(task.updatedOn).toLocaleString("en-GB");
    const reminderDate = new Date(task.reminderDate).toLocaleString("en-GB");

    let body = "<table style=\"font-family: Tahoma;\"><tbody>";
    body += this.tableRow("Created by:", task.createdByUserAccount.fullName);
    body += this.tableRow("Created on:", createdOnDate);
    body += this.tableRow("<br/>", "<br/>");
    body += task.updatedByUserAccountId ? this.tableRow("Updated by:", task.updatedByUserAccount.fullName) : "";
    body += task.updatedByUserAccountId ? this.tableRow("Updated on:", updatedOnDate) : "";
    body += task.updatedByUserAccountId ? this.tableRow("<br/>", "<br/>") : "";
    body += this.tableRow("Scheduled for:", reminderDate);
    body += task.location ? this.tableRow("Location:", task.location) : "";
    body += this.tableRow("<br/>", "<br/>");

    const contactVal = task.contactTasks.length > 1 ? "Assigned Contacts:" : "Assigned Contact:";
    task.contactTasks.forEach(
      (contactTask, index) => {
        body += index === 0 ?
          this.tableRow(contactVal, contactTask.contact.fullName) :
          this.tableRow("", contactTask.contact.fullName);
      }
    );
    body += this.tableRow("<br/>", "<br/>");

    const agentVal = task.userTasks.length > 1 ? "Assigned Agents:" : "Assigned Agent:";
    task.userTasks.forEach(
      (userTask, index) => {
        body += index === 0 ?
          this.tableRow(agentVal, userTask.user.fullName) :
          this.tableRow("", userTask.user.fullName);
      }
    );
    body += this.tableRow("<br/>", "<br/>");

    body += this.tableRow("Task Description:", task.taskDescription);
    body += "</tbody></table>";

    const mail: any = {
      userReceiver: receiver,
      contactReceiver: null,
      subject: subject,
      body: intro + body,
      task: task,
      calendarDuration: calendarDuration
    }
    return mail;
  }

  createContactTaskEmail(task: Task, sender: CrmUser, receiver: Contact, calendarDuration: number) {
    let subject = sender.fullName;
    subject += task.updatedByUserAccountId ?
      " has updated a task that you are assigned to" :
      " has assigned you to a new task";

    let intro = "Hello " + receiver.fullName + "<br/><br/>";
    intro += task.updatedByUserAccountId ?
      "The following task has been updated:" :
      "You have been assigned to the following task:";
    intro += "<br/><br/>";

    const createdOnDate = new Date(task.createdOn).toLocaleString("en-GB");
    const updatedOnDate = new Date(task.updatedOn).toLocaleString("en-GB");
    const reminderDate = new Date(task.reminderDate).toLocaleString("en-GB");

    let body = "<table style=\"font-family: Tahoma;\"><tbody>";
    body += this.tableRow("Created by:", task.createdByUserAccount.fullName);
    body += this.tableRow("Created on:", createdOnDate);
    body += this.tableRow("<br/>", "<br/>");
    body += task.updatedByUserAccountId ? this.tableRow("Updated by:", task.updatedByUserAccount.fullName) : "";
    body += task.updatedByUserAccountId ? this.tableRow("Updated on:", updatedOnDate) : "";
    body += task.updatedByUserAccountId ? this.tableRow("<br/>", "<br/>") : "";
    body += this.tableRow("Scheduled for:", reminderDate);
    body += task.location ? this.tableRow("Location:", task.location) : "";
    body += this.tableRow("<br/>", "<br/>");

    const contactVal = task.contactTasks.length > 1 ? "Assigned Contacts:" : "Assigned Contact:";
    task.contactTasks.forEach(
      (contactTask, index) => {
        body += index === 0 ?
          this.tableRow(contactVal, contactTask.contact.fullName) :
          this.tableRow("", contactTask.contact.fullName);
      }
    );
    body += this.tableRow("<br/>", "<br/>");

    const agentVal = task.userTasks.length > 1 ? "Assigned Agents:" : "Assigned Agent:";
    task.userTasks.forEach(
      (userTask, index) => {
        body += index === 0 ?
          this.tableRow(agentVal, userTask.user.fullName) :
          this.tableRow("", userTask.user.fullName);
      }
    );
    body += this.tableRow("<br/>", "<br/>");

    body += this.tableRow("Task Description:", task.taskDescription);
    body += "</tbody></table>";

    const mail: any = {
      userReceiver: null,
      contactReceiver: receiver,
      subject: subject,
      body: intro + body,
      task: task,
      calendarDuration: calendarDuration
    }
    return mail;
  }

  tableRow(colA: string, colB: string): string {
    return "<tr>"
      + "<td style=\"min-width: 200px; width: 200px; vertical-align:top;\">" + colA + "</td>"
      + "<td style=\"vertical-align:top;\">" + colB + "</td>"
      + "</tr>";
  }
}