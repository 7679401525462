import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { smoothlyMenu } from '../../../app.helpers';

import { AuthService } from "../../../views/auth/auth.service";
import { ContactService } from '../../../views/shared/services/contact.service';

import { CookieService } from "ngx-cookie";

import { Contact } from '../../../views/shared/models/contact.model';
import { DeveloperModeService, DeveloperModeHelper } from 'app/views/shared/developer.mode.service';

declare var jQuery: any;

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavbar.template.html'
})
export class TopNavbarComponent implements AfterViewInit {
  contactsOptions: Contact[] = [];
  selectedContactOption: Contact;

  developerMode$ = null;
 isDevModeEnabled = false;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authService: AuthService,
    private contactService: ContactService,
    private devMode: DeveloperModeService,
    private devModeHelper: DeveloperModeHelper
  ) { }

  ngAfterViewInit(): void {
    let isExpanded = this.cookieService.getObject("crm_isSideNavExpanded") as boolean;
    if (isExpanded !== false) // - Wide navigation if no cookie is found (undefined) or if value is 'true'
      this.toggleWideNavigation();
    else
      this.toggleMiniNavigation();

      this.developerMode$ = this.devModeHelper.isDeveloperModeEnabled.subscribe((isDeveloper) => {
        this.isDevModeEnabled = isDeveloper;
      });
  }

  searchAutoCompleteForContacts(event) {
    if (event.query) {
      this.onGetContactsSearch(event.query);
    }
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm, false).subscribe(
      (contacts: Contact[]) => {
        this.contactsOptions = contacts;
      }
    );
  }

  onContactSelect(event) {
    this.router.navigate(['/contacts/' + this.selectedContactOption.id + '/edit']);
    this.selectedContactOption = null; // - Clear search field after search complete
  }

  onLogOut() {
    this.authService.logOut();
  }

  toggleNavigation(): void {
    jQuery("body").toggleClass("mini-navbar");
    smoothlyMenu();

    this.cookieService.putObject("crm_isSideNavExpanded", this.isSideNavExpanded());
  }

  isSideNavExpanded(): boolean {
    if (jQuery("body").hasClass("mini-navbar"))
      return false;

    return true;
  }

  toggleWideNavigation(): void {
    jQuery("body").removeClass("mini-navbar");
  }

  toggleMiniNavigation(): void {
    jQuery("body").addClass("mini-navbar");
  }

  onToggleDeveloperMode() {
    if(!this.isDevModeEnabled) {
      this.onEnableDeveloperMode();
    } else {
      this.onDisableDeveloperMode();
    }
  }

  @ViewChild('chk') chk: ElementRef;


  onEnableDeveloperMode() {
    // this.chk.nativeElement.disable();
    this.devMode.enableDeveloperMode().subscribe(()=>{
      this.devModeHelper.next(true);
      // this.chk.nativeElement.enable();
    }, () => {
      this.devModeHelper.next(false);
    });
  }

  onDisableDeveloperMode() {
    // this.chk.nativeElement.disable();

    this.devMode.disableDeveloperMode().subscribe(()=>{
      this.devModeHelper.next(false);
      // this.chk.nativeElement.enable();
    }, ()=> {
      this.devModeHelper.next(true);
    });
  }
}
