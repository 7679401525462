import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../../auth/auth.service";
import { ContactTypeService } from "../../../shared/services/contact-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { ContactType } from "../../../shared/models/contact-type.model";

import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-contact-type-list',
  templateUrl: './contact-type-list.component.html',
  styleUrls: ['./contact-type-list.component.scss']
})
export class ContactTypeListComponent implements OnInit {
  contactTypes: ContactType[] = [];
  isAnim: boolean = true;

  systemCreatedById = PrepopulatedEntities.SystemContactTypeCreatedById; // - Id of System used to check if contact type was prepopulated (Added by system)

  constructor(
    public authService: AuthService,
    private contactTypeService: ContactTypeService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetContactTypes();

    // Listen to changes made to contactTypes
    this.contactTypeService.typesChanged.subscribe(
      () => {
        this.onGetContactTypes();
      }
    );
  }

  onGetContactTypes() {
    this.contactTypeService.getContactTypes().subscribe(
      (contactTypes: ContactType[]) => {
        this.contactTypes = contactTypes;
        console.log(this.contactTypes);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }

  onDeleteContactType(contactType: ContactType) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the contact type ' + contactType.name + '?',
      accept: () => {
        contactType.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactTypeService.deleteContactType(contactType).subscribe(
          (response: Response) => {
            this.onGetContactTypes();
            this.toastService.createSuccessMessage("Success", "The contact type " + contactType.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting contact type", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for contact type " + contactType.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
