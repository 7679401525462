import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { SharedModule } from "../shared/shared.module";
import { AppRoutingModule } from "../../app-routing.module";

import { AuthGuard } from "../auth/auth-guard.service";
import { RoleGuard } from "../auth/role-guard.service";
import { SuperAdminGuard } from '../auth/super-admin-guard.service';

import { AuthService } from "../auth/auth.service";
import { UserService } from "../shared/services/user.service";
import { ContactService } from "../shared/services/contact.service";
import { ContactTypeService } from "../shared/services/contact-type.service";
import { ContactSourceService } from "../shared/services/contact-source.service";
import { CommunicationHistoryService } from "../shared/services/communication-history.service";
import { CommunicationHistoryTypeService } from "../shared/services/communication-history-type.service";
import { TaskService } from "../shared/services/task.service";
import { ToastService } from "../shared/services/toast.service";
import { LoadingSpinnerService } from "../shared/services/loading-spinner.service";
import { ConfirmationService } from "primeng/components/common/api";
import { EmailService } from "../shared/services/email.service";
import { OpportunityService } from "../shared/services/opportunity.service";
import { OpportunityTypeService } from "../shared/services/opportunity-type.service";
import { ContactFileService } from "../shared/services/contact-file.service";
import { OpportunityFileService } from "../shared/services/opportunity-file.service";
import { TagService } from '../shared/services/tag.service';
import { UtilityService } from "../shared/services/utility.service";
import { TableUtilityService } from "../shared/services/table-utility.service";
import { SmsWarriorsService } from '../shared/services/sms-warriors.service';
import { MessageTemplateService } from '../shared/services/message-template.service';
import { SmsWarriorsProfileService } from '../shared/services/sms-warriors-profile.service';
import { EmailProfileService } from '../shared/services/email-profile.service';
import { OpportunityLostTypeService } from '../shared/services/opportunity-lost-type.service';
import { MailchimpService } from '../shared/services/mailchimp.service';
import { TaskTypeService } from '../shared/services/task-type.service';
import { ProductService } from '../shared/services/product.service';
import { GroupService } from '../shared/services/group.service';
import { SavedContactSearchService } from '../shared/services/saved-contact-search.service';
import { LeadDeskService } from '../shared/services/lead-desk.service';
import { OrganizationService } from '../shared/services/organization.service';
import { MessageSignatureService } from '../shared/services/message-signature.service';
import { ComplaintService } from '../shared/services/complaint.service';
import { ComplaintTypeService } from '../shared/services/complaint-type.service';
import { ComplaintLogService } from '../shared/services/complaint-log.service';

import { CustomFormBuilder } from "../shared/classes/CustomFormBuilder";
import { FormUtilityService } from 'app/shared/form-utility.service';
import { PaymentTermService } from '../shared/services/payment-term.service';
import { QuoteService } from '../shared/services/quote.service';

@NgModule({
  declarations: [

  ],
  imports: [
    SharedModule,
    AppRoutingModule
  ],
  exports: [
    AppRoutingModule
  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard,
    RoleGuard,
    SuperAdminGuard,
    FormUtilityService,
    // AuthService,
    UserService,
    ContactService,
    ContactTypeService,
    ContactSourceService,
    CommunicationHistoryService,
    CommunicationHistoryTypeService,
    TaskService,
    ConfirmationService,
    ToastService,
    LoadingSpinnerService,
    EmailService,
    OpportunityService,
    OpportunityTypeService,
    ContactFileService,
    OpportunityFileService,
    TagService,
    SmsWarriorsService,
    MailchimpService,
    MessageTemplateService,
    SmsWarriorsProfileService,
    EmailProfileService,
    OpportunityLostTypeService,
    TaskTypeService,
    ProductService,
    GroupService,
    SavedContactSearchService,
    LeadDeskService,

    UtilityService,
    TableUtilityService,
    OrganizationService,
    MessageSignatureService,
    ComplaintService,
    ComplaintTypeService,
    PaymentTermService,
    ComplaintLogService,
    CustomFormBuilder,
    QuoteService,
  ]
})
export class CoreModule { }
