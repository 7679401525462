import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { ContactType } from "../models/contact-type.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ContactTypeService {
  private url: string = AppSettings.API_ENDPOINT + 'api/contacttypes/';

  public typesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getContactTypes(): Observable<ContactType[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let contactTypes: ContactType[] = (<any>response);
        contactTypes = contactTypes.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return contactTypes;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getContactType(id: number): Observable<ContactType> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let contactType: ContactType = (<any>response);
        // contactType.createdByUserAccount = this.userService.getUser(contactType.createdByUserAccountId);
        // contactType.updatedByUserAccount = this.userService.getUser(contactType.updatedByUserAccountId);
        return contactType;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addContactType(contactType: ContactType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      contactType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateContactType(contactType: ContactType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + contactType.id,
      contactType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteContactType(contactType: ContactType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + contactType.id,
      contactType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}