import { Component, OnInit } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../auth/auth.service";
import { ContactService } from "../shared/services/contact.service";
import { OpportunityService } from "../shared/services/opportunity.service";
import { UtilityService } from "../shared/services/utility.service";
import { TaskService } from 'app/views/shared/services/task.service';
import { CookieService } from 'ngx-cookie';

import { Contact } from "../shared/models/contact.model";
import { OpportunityProgress } from "../shared/models/opportunity-progress.model";
import { Opportunity } from "app/views/shared/models/opportunity.model";
import { PrepopulatedContactTypes, ApiEndPoints, ComponentStyling, PrepopulatedOpportunityTypes } from "app.constant";
import { Task } from "../shared/models/task.model";

import { SelectItem } from "primeng/primeng";
import { ToastService } from 'app/views/shared/services/toast.service';
import { CallInfo } from 'app/views/shared/models/lead-desk/call-info.model';
import { AppSettings } from 'app.settings';
import { DeveloperModeHelper } from '../shared/developer.mode.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  totalLeadsCount: number = 0;
  totalLeadsCountText: string = "Daily";

  conversionsThisMonthCount: number = 0;          // - The number of contacts converted from 'Lead' to 'Contact'
  conversionsThisMonthCountText: string = "Daily";

  totalOpenOpportunitiesCount: number = 0;
  monthlyOpenOpportunitiesCount: number = 0;
  dailyOpenOpportunitiesCount: number = 0;
  openOpportunitiesCountToDisplay: number = 0;
  openOpportunitiesCountToDisplayText: string = "Daily";

  totalClosedOppCount: number=0;
  totalClosedOpportunitiesCount: number = 0;      // - The number of opportunities with type 'Closed'
  closedOpportunitiesThisMonthCount: number = 0;
  dailyClosedOpportunitiesCount: number = 0;
  closedOpportunitiesCountToDisplay: number = 0;
  closedOpportunitiesCountToDisplayText: string = "Daily";

  totalLostOpportunitiesCount: number = 0;        // - The number of opportunities with type 'Lost'

  tasks: Task[] = [];                   // - List of all tasks
  todayTasks: Task[] = [];              // - List of all tasks for today
  todayTasksDone: Task[] = [];          // - List of all done tasks for today
  lateTasks: Task[] = [];               // - List of all late tasks

  crmApiEndPoint: string = AppSettings.API_ENDPOINT;

  loggedUserName: String = '';

  //** Line Chart */
  opportunities: Opportunity[] = [];              // - Stores all Opportunities
  opportunitiesWithProgress: Opportunity[] = [];  // - Stores all Opportunities with a progress history
  opportunitiesOptions: SelectItem[] = [];        // - Stores Opportunity Dropdown Options for the LineChart
  selectedOpportunityOption: any;                 // - Stores the selected dropdown option for opportunity
  chartData: any;                                 // - The data to be displayed in the chart
  oppChartData: any;                              // - The data to be displayed in the opportunity chart

  weekDays: string[] = [                          // - The days of the week, you can change values but do not change the order!
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];
  oppChartLabels: string[] = [
    PrepopulatedOpportunityTypes.Open, PrepopulatedOpportunityTypes.Closed, PrepopulatedOpportunityTypes.Lost
  ]
  chartOptions: any = {
    series: {
      lines: {
        show: false,
        fill: true
      },
      splines: {
        show: true,
        tension: 0.4,
        lineWidth: 1,
        fill: 0.4
      },
      points: {
        radius: 0,
        show: true
      },
      shadowSize: 2
    },
    grid: {
      hoverable: true,
      clickable: true,
      tickColor: "#d5d5d5",
      borderWidth: 1,
      color: '#d5d5d5'
    },
    elements: {
      line: {
        tension: 0.4 // - Disables line curves in line chart
      }
    },
    scales: {
      yAxes: [{
        stacked: true,
      }]
    },
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false
  };

  oppChartOptions: any = {
    legend: {
      display: false
    }
  };

  accessToken = localStorage.getItem('accessToken');
  isDev: boolean;
  developerMode$: any;

  constructor(
    private authService: AuthService,
    private contactService: ContactService,
    private opportunityService: OpportunityService,
    private utilService: UtilityService,
    private taskService: TaskService,
    private cookieService: CookieService,
    private toastService: ToastService,
    private devMode: DeveloperModeHelper
  ) { }

  ngOnInit() {

    this.onGetContactsCount();
    this.onGetOpportunitiesForDashboard();
    this.onGetTasks();

    this.loggedUserName = this.authService.getUser().profile['given_name'];

    this.developerMode$ = this.devMode.isDeveloperModeEnabled.subscribe((isDev)=> {
      this.isDev = isDev;
    })
  }

  onGetContactsCount() {
    let leadWidgetType = this.cookieService.getObject("crm_widgetLeadsType") as number;
    leadWidgetType = leadWidgetType ? leadWidgetType : 1;

    let convertedLeadWidgetType = this.cookieService.getObject("crm_widgetConvertedLeadsType") as number;
    convertedLeadWidgetType = convertedLeadWidgetType ? convertedLeadWidgetType : 1;

    this.onChangeLeadsCount(leadWidgetType);
    this.onChangeConvertedLeadsCount(convertedLeadWidgetType);
  }

  onChangeLeadsCount(type: number) {
    this.contactService.getLeadsCount(type).subscribe(
      (leadsCount: number) => {
        this.totalLeadsCount = leadsCount;

        this.cookieService.putObject("crm_widgetLeadsType", type);

        switch (type) {
          case 1:
            this.totalLeadsCountText = "Daily"
            break;
          case 2:
            this.totalLeadsCountText = "Monthly"
            break;
          case 3:
            this.totalLeadsCountText = "All-Time"
            break;
          default:
            this.totalLeadsCountText = "Daily"
            break;
        }
      }
    );
  }

  onChangeConvertedLeadsCount(type: number) {
    this.contactService.getLeadsConvertedThisMonthCount(type).subscribe(
      (leadsCount: number) => {
        this.conversionsThisMonthCount = leadsCount;

        this.cookieService.putObject("crm_widgetConvertedLeadsType", type);

        switch (type) {
          case 1:
            this.conversionsThisMonthCountText = "Daily"
            break;
          case 2:
            this.conversionsThisMonthCountText = "Monthly"
            break;
          case 3:
            this.conversionsThisMonthCountText = "All-Time"
            break;
          default:
            this.conversionsThisMonthCountText = "Daily"
            break;
        }
      }
    );
  }

  onChangeOpenOpportunitiesCount(type: number) {
    this.cookieService.putObject("crm_widgetOpenOpportunitiesType", type);

    switch (type) {
      case 1:
        this.openOpportunitiesCountToDisplayText = "Daily"
        this.openOpportunitiesCountToDisplay = this.dailyOpenOpportunitiesCount;
        break;
      case 2:
        this.openOpportunitiesCountToDisplayText = "Monthly"
        this.openOpportunitiesCountToDisplay = this.monthlyOpenOpportunitiesCount;
        break;
      case 3:
        this.openOpportunitiesCountToDisplayText = "All-Time"
        this.openOpportunitiesCountToDisplay = this.totalOpenOpportunitiesCount;
        break;
      default:
        this.openOpportunitiesCountToDisplayText = "Daily"
        this.openOpportunitiesCountToDisplay = this.dailyOpenOpportunitiesCount;
        break;
    }
  }

  onChangeClosedOpportunitiesCount(type: number) {
    this.cookieService.putObject("crm_widgetClosedOpportunitiesType", type);

    switch (type) {
      case 1:
        this.closedOpportunitiesCountToDisplayText = "Daily"
        this.closedOpportunitiesCountToDisplay = this.dailyClosedOpportunitiesCount;
        break;
      case 2:
        this.closedOpportunitiesCountToDisplayText = "Monthly"
        this.closedOpportunitiesCountToDisplay = this.closedOpportunitiesThisMonthCount;
        break;
      case 3:
        this.closedOpportunitiesCountToDisplayText = "All-Time"
        this.closedOpportunitiesCountToDisplay = this.totalClosedOppCount;//this.totalClosedOpportunitiesCount;
        break;
      default:
        this.closedOpportunitiesCountToDisplayText = "Daily"
        this.closedOpportunitiesCountToDisplay = this.dailyClosedOpportunitiesCount;
        break;
    }
  }

  onGetOpportunitiesForDashboard() {
    this.opportunityService.getOpportunitiesForDashboard().subscribe(
      (opportunities: Opportunity[]) => {
        this.opportunities = opportunities;

        // - Get the number of Opportunities of type 'Open'
        this.totalOpenOpportunitiesCount =
          this.opportunities.filter(o => o.opportunityType.name === PrepopulatedOpportunityTypes.Open).length;

        this.monthlyOpenOpportunitiesCount =
          this.opportunities.filter(o =>
            o.opportunityType.name === PrepopulatedOpportunityTypes.Open &&
            this.utilService.isDateInCurrentMonth(new Date(o.createdOn))
          ).length;

        this.dailyOpenOpportunitiesCount =
          this.opportunities.filter(o =>
            o.opportunityType.name === PrepopulatedOpportunityTypes.Open &&
            new Date(o.createdOn).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)
          ).length;

        let openOppWidgetType = this.cookieService.getObject("crm_widgetOpenOpportunitiesType") as number;
        openOppWidgetType = openOppWidgetType ? openOppWidgetType : 1;

        this.onChangeOpenOpportunitiesCount(openOppWidgetType);

        // - Get the number of Opportunities of type 'Closed'
        this.totalClosedOpportunitiesCount =
          this.opportunities.filter(o => o.opportunityType.name === PrepopulatedOpportunityTypes.Closed).length;
        // - Get the number of Opportunities of type 'Lost'
        this.totalLostOpportunitiesCount =
          this.opportunities.filter(o => o.opportunityType.name === PrepopulatedOpportunityTypes.Lost).length;

        let closedOpps = opportunities.filter(o => o.closedDate);
        this.totalClosedOppCount = closedOpps.length;
        let closedOppsThisMonth = closedOpps.filter(o => this.utilService.isDateInCurrentMonth(new Date(o.closedDate)));
        this.closedOpportunitiesThisMonthCount = closedOppsThisMonth.length;

        this.dailyClosedOpportunitiesCount =
          closedOpps.filter(o =>
            new Date(o.closedDate).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)
          ).length;

        let closedOppWidgetType = this.cookieService.getObject("crm_widgetClosedOpportunitiesType") as number;
        closedOppWidgetType = closedOppWidgetType ? closedOppWidgetType : 1;

        this.onChangeClosedOpportunitiesCount(closedOppWidgetType);

        this.oppChartData = {
          labels: this.oppChartLabels,
          datasets: [
            {
              data: [this.totalOpenOpportunitiesCount, this.totalClosedOpportunitiesCount, this.totalLostOpportunitiesCount],
              backgroundColor: ['#a3e1d4', '#dedede', '#9CC3DA']
            }
          ]
        }

        this.setupChart();
      }
    );
  }

  /** Opportunity Progression LineChart - Start */
  setupChart() {
    // - Sort stored list of retrieved opportunities by the most recently created opportunities
    this.opportunities = this.utilService.sortListByValueName(this.opportunities, "createdOn", false);

    // - Get only the opportunities that have at least 1 Opportunity Progress record
    this.opportunitiesWithProgress = this.opportunities.filter(o => o.opportunityProgress.length > 0 && o.opportunityType.name !== PrepopulatedOpportunityTypes.Lost);

    this.opportunitiesOptions = [];
    this.opportunitiesOptions.push({ label: "General Progress", value: null });

    // - Store the filtered opportunities in the opportunitiesOptions select list
    this.opportunitiesWithProgress.map(o => {
      let labelText = o.name;
      labelText += " / Type: " + o.opportunityType.name;

      this.opportunitiesOptions.push(
        {
          label: labelText,
          value: o
        }
      );
    });

    this.onGetChartData(this.opportunitiesWithProgress); // - Init chart with all Opportunities' Progress records
  }

  // selectData(event) {
  //   console.log("SELECTED DATA, EVENT VALUE IS: ", event);
  // }

  onOpportunityOptionChange() {
    if (this.selectedOpportunityOption)
      this.onGetChartData([this.selectedOpportunityOption]);
    else
      this.onGetChartData(this.opportunitiesWithProgress);
  }

  onGetChartData(opportunities: Opportunity[]) {
    this.chartData = {
      labels: this.weekDays,
      datasets: []
    };

    let datasetList = [];

    // - Iterate through each Opportunity
    for (let i = 0; i < opportunities.length; i++) {

      // - Create a dataset for the current opportunity
      let dataset = {
        label: opportunities.length > 1 ? opportunities[i].name : "Progress Points for " + opportunities[i].name,
        data: [],
        fill: opportunities.length > 1 ? false : true,
        borderColor: this.utilService.getRandomColor()
      };

      let currAllOpportunityProgress = opportunities[i].opportunityProgress;
      // - First sort by most recently created
      currAllOpportunityProgress = currAllOpportunityProgress.sort(
        (obj1, obj2) => {
          const obj1CreatedOnDate = new Date(obj1.createdOn);
          const obj2CreatedOnDate = new Date(obj2.createdOn);

          if (obj1CreatedOnDate > obj2CreatedOnDate)
            return -1;
          if (obj1CreatedOnDate < obj2CreatedOnDate)
            return 1;

          return 0;
        }
      );

      // - Get the Opportunity Progress records that took place in the current week
      let weekOpportunityProgress = currAllOpportunityProgress.filter(oP => this.utilService.isDateInCurrentWeek(new Date(oP.createdOn)));

      // - If any then plot those progressions
      if (weekOpportunityProgress.length > 0) {
        // - Iterate through each day of the week and get the progress value for each day
        for (let day = 0; day <= 6; day++)
          dataset = this.getOppProgressByDay(day, currAllOpportunityProgress, weekOpportunityProgress, dataset);
      }
      // - Otherwise we find the most recent opp progress and plot that value to "continue" the data from the past
      else {
        if (currAllOpportunityProgress.length > 0) {
          let mostRecentOppProgress = currAllOpportunityProgress[0];
          let todayWeekDay = new Date().getDay();

          for (let day = 0; day <= todayWeekDay; day++) {
            dataset.data.push(this.getPercentOfOpportunityValue(mostRecentOppProgress));
          }
        }
      }

      datasetList.push(dataset);
    }

    if (datasetList.length == 1)
      this.chartData.datasets.push(datasetList[0]);
    else {
      // - Create a new dataset from the datasetList's data
      let newDataset = {
        label: "General Progress",
        data: [],
        fill: true,
        borderColor: this.utilService.getRandomColor()
      };

      let mergedData = [];

      let todayWeekDay = new Date().getDay();

      for (let day = 0; day <= todayWeekDay; day++) {
        let totalDayProgress = 0;

        for (let dataset of datasetList) {
          totalDayProgress += dataset.data[day];
        }
        mergedData.push(totalDayProgress);
      }
      newDataset.data = mergedData;
      this.chartData.datasets.push(newDataset);
    }
  }

  getPercentOfOpportunityValue(oppProgress: OpportunityProgress) {
    return this.utilService.getPercentOfValue(oppProgress.probability, oppProgress.value);
  }

  getMostRecentOppProgress(opportunityProgress: OpportunityProgress[]) {
    // - Sort by most recently created
    opportunityProgress = opportunityProgress.sort(
      (obj1, obj2) => {
        const obj1CreatedOnDate = new Date(obj1.createdOn);
        const obj2CreatedOnDate = new Date(obj2.createdOn);

        if (obj1CreatedOnDate > obj2CreatedOnDate)
          return -1;
        if (obj1CreatedOnDate < obj2CreatedOnDate)
          return 1;

        return 0;
      }
    );

    // - Return first one since most recently created is on top
    return opportunityProgress[0];
  }

  getOppProgressByDay(day: number, allOpportunityProgress: OpportunityProgress[], weekOpportunityProgress: OpportunityProgress[], dataset: any) {
    // - First retrieve the opportunity progress of the provided day (0 is Sunday, 6 is Saturday)
    let currentDayOpportunityProgress = weekOpportunityProgress.filter(oP => new Date(oP.createdOn).getDay() === day);

    // - Get all of the probability values that occurred on the provided day
    // let data = currentDayOpportunityProgress.map(oP => oP.probability);
    let data = this.getMostRecentOppProgress(currentDayOpportunityProgress);

    // - If data was present then push the most recent probability changes that happened on the provided day
    if (data)
      dataset.data.push(this.getPercentOfOpportunityValue(data));
    else {
      // - If no data was present then push the most recent data if the day has not passed yet
      //// - This allows for the chart to plot the most recent value if no changes in progress took place
      let todayWeekDay = new Date().getDay();

      if (day <= todayWeekDay) {
        let previousData = dataset.data[dataset.data.length - 1];

        if (previousData)
          dataset.data.push(previousData);
        else {
          let previousOpportunityProgress = allOpportunityProgress.filter(oP => !this.utilService.isDateInCurrentWeek(new Date(oP.createdOn)));

          if (previousOpportunityProgress.length > 0)
            dataset.data.push(this.getPercentOfOpportunityValue(previousOpportunityProgress[0]));
          else
            dataset.data.push(null);
        }
      }
    }

    return dataset;
  }
  /** Opportunity Progression LineChart - End */

  /**
   * @TODO REFRACTOR
   */
  onGetTasks() {
    // Admins can see everyone's tasks
    if (this.authService.isSuperAdmin()) {
      this.taskService.getTasks().subscribe(
        (tasks: Task[]) => {
          this.storeTasks(tasks);
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    }
    // Users may only see their own tasks
    else {
      this.taskService.getTasksByUserId(this.authService.applicationProfileUser().id).subscribe(
        (tasks: Task[]) => {
          this.storeTasks(tasks);
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    }
  }

  /**
   * @TODO REFRACTOR
   */
  storeTasks(tasks: Task[]) {
    // - Dashboard shows task records that are for today (and also late tasks)
    this.tasks = tasks;
    this.lateTasks = tasks.filter(t => this.taskService.isTaskLate(t));
    this.todayTasks = tasks.filter(t => this.taskService.isTaskForToday(t, true));
    this.todayTasksDone = tasks.filter(t => this.taskService.isTaskForToday(t, false));
    this.todayTasksDone = this.todayTasksDone.filter(t => t.isTaskComplete);
  }
}
