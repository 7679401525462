import { Injectable } from '@angular/core';
import { SelectItem } from "primeng/primeng";

@Injectable()
export class TableUtilityService {
  //** Contacts Table - Start */
  //**** - All Toggleable  Columns */
  private allSelectableContactCols: any[] = [
    { label: 'Date Created', value: 'createdOn' },
    { label: 'Created By', value: 'createdByUserAccount' },
    { label: 'Date Updated', value: 'updatedOn' },
    { label: 'Updated By', value: 'updatedByUserAccount' },
    { label: 'Assigned To', value: 'assignedUser' },
    { label: 'Full Name', value: 'fullName' },
    { label: 'Organization', value: 'organization' },
    { label: 'Type', value: 'contactType' },
    { label: 'Source', value: 'contactSource' },
    { label: 'Referred By', value: 'referredByContact' },
    { label: 'Date Converted', value: 'convertedDate' },
    { label: 'Company Name', value: 'companyName' },
    { label: 'Email', value: 'email' },
    { label: 'Mobile', value: 'mobilePhoneNumber' },
    { label: 'Home Tel', value: 'homePhoneNumber' },
    { label: 'Office Tel', value: 'officePhoneNumber' },
    { label: 'Alternate Tel', value: 'alternatePhoneNumber' },
    { label: 'Birthdate', value: 'birthdate' },
    { label: 'ID Card', value: 'idCardNumber' },
    { label: 'Vat', value: 'vatNumber' },
    { label: 'Notes', value: 'notes' },
    { label: 'Options', value: 'options' }
  ];

  /**** - Contacts table - Default Columns */  
  private selectedContactColsDefault: any[] = [
    // { label: 'Date Created', value: 'createdOn' },
    { label: 'Assigned To', value: 'assignedUser' },
    { label: 'Organization', value: 'organization' },
    { label: 'Full Name', value: 'fullName' },
    { label: 'Company Name', value: 'companyName' },
    { label: 'Email', value: 'email' },
    // { label: 'Mobile', value: 'mobilePhoneNumber' },
    { label: 'Options', value: 'options' }
  ];
  //** Contacts Table - End */

  //** Tasks Table - Start */
  //**** - All Toggleable  Columns */
  private allSelectableTaskCols: any[] = [
    { label: 'Status', value: 'taskStatus' },
    { label: 'Date Created', value: 'createdOn' },
    { label: 'Created By', value: 'createdByUserAccount' },
    { label: 'Date Updated', value: 'updatedOn' },
    { label: 'Updated By', value: 'updatedByUserAccount' },
    { label: 'Scheduled For', value: 'reminderDate' },
    { label: 'Description', value: 'taskDescription' },
    { label: 'Task Type', value: 'taskType' },
    { label: 'Assigned Agents', value: 'userTasks' },
    { label: 'Assigned Contacts', value: 'contactTasks' },
    { label: 'Location', value: 'location' },
    { label: 'Complete', value: 'isTaskComplete' },
    { label: 'Options', value: 'options' }    
  ];

  /**** - Default Columns */  
  private selectedTaskColsDefault: any[] = [
    { label: 'Status', value: 'taskStatus' },
    { label: 'Created By', value: 'createdByUserAccount' },
    { label: 'Scheduled For', value: 'reminderDate' },
    { label: 'Description', value: 'taskDescription' },
    { label: 'Task Type', value: 'taskType' },    
    { label: 'Assigned Agents', value: 'userTasks' },
    { label: 'Assigned Contacts', value: 'contactTasks' },
    { label: 'Location', value: 'location' },
    { label: 'Complete', value: 'isTaskComplete' },
    { label: 'Options', value: 'options' }    
  ];
  //** Tasks Table - End */

  //** Opportunity Table - Start */
  //**** - All Toggleable  Columns */
  private allSelectableOpportunityCols: any[] = [
    { label: 'Date Created', value: 'createdOn' },
    { label: 'Created By', value: 'createdByUserAccount' },
    { label: 'Date Updated', value: 'updatedOn' },
    { label: 'Updated By', value: 'updatedByUserAccount' },
    { label: 'Product ID', value: 'uniqueId' },
    { label: 'Assigned To', value: 'assignedUser' },
    { label: 'Contact', value: 'assignedContact' },
    { label: 'Type', value: 'opportunityType' },
    { label: 'Name', value: 'name' },
    { label: 'Description', value: 'description' },
    { label: 'Probability', value: 'probability' },
    { label: 'Value', value: 'value' },
    { label: 'Options', value: 'options' }
  ];

  /**** - Default Columns */  
  private selectedOpportunityColsDefault: any[] = [
    { label: 'Date Created', value: 'createdOn' },
    { label: 'Assigned To', value: 'assignedUser' },
    { label: 'Contact', value: 'assignedContact' },
    { label: 'Type', value: 'opportunityType' },
    { label: 'Name', value: 'name' },
    { label: 'Description', value: 'description' },
    { label: 'Probability', value: 'probability' },
    { label: 'Options', value: 'options' }
  ];
  //** Opportunity Table - End */


  //** Organizations Table - Start */
  //**** - All Toggleable  Columns */
  private allSelectableOrganizationCols: any[] = [
    { label: 'Date Created', value: 'createdOn' },
    { label: 'Created By', value: 'createdByUserAccount' },
    { label: 'Date Updated', value: 'updatedOn' },
    { label: 'Updated By', value: 'updatedByUserAccount' },
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
    { label: 'Mobile', value: 'mobilePhoneNumber' },
    { label: 'Office Tel.', value: 'officePhoneNumber' },
    { label: 'Building Name', value: 'addressLine1' },
    { label: 'Street Name', value: 'addressLine2' },
    { label: 'Locality', value: 'addressLine3' },
    { label: 'Post Code', value: 'addressLine4' },
    { label: 'Default Contact', value: 'defaultContact' },
  ];

  /**** - Default Columns */  
  private selectedOrganizationColsDefault: any[] = [
    { label: 'Status', value: 'taskStatus' },
    { label: 'Created By', value: 'createdByUserAccount' },
    { label: 'Name', value: 'name' },
    { label: 'Email', value: 'email' },
    { label: 'Mobile', value: 'mobilePhoneNumber' },
    { label: 'Default Contact', value: 'defaultContact' },
  ];
  //** Organizations Table - End */

  constructor() { }

  getAllSelectableContactCols() {
    return this.allSelectableContactCols;
  }

  getSelectedContactColsDefault() {
    return this.selectedContactColsDefault;
  }

  getAllSelectableTaskCols() {
    return this.allSelectableTaskCols;
  }

  getSelectedTaskColsDefault() {
    return this.selectedTaskColsDefault;
  }

  getAllSelectableOpportunityCols() {
    return this.allSelectableOpportunityCols;
  }

  getSelectedOpportunityColsDefault() {
    return this.selectedOpportunityColsDefault;
  }

  getAllSelectableOrganizationCols() {
    return this.allSelectableOrganizationCols;
  }

  getSelectedOrganizationColsDefault() {
    return this.selectedOrganizationColsDefault;
  }

  getColumnOptions(columnOptions: SelectItem[], allSelectableCols: any[]) {
    columnOptions = [];

    for (let i = 0; i < allSelectableCols.length; i++) {
      columnOptions.push({
        label: allSelectableCols[i].label,
        value: allSelectableCols[i]
      });
    }

    return columnOptions;
  }
}
