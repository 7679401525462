import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { ContactService } from '../../shared/services/contact.service';
import { EmailService } from '../../shared/services/email.service';
import { MessageTemplateService } from '../../shared/services/message-template.service';
import { MessageSignatureService } from '../../shared/services/message-signature.service';
import { EmailProfileService } from '../../shared/services/email-profile.service';
import { ToastService } from '../../shared/services/toast.service';
import { CommunicationHistoryService } from '../../shared/services/communication-history.service';
import { GroupService } from '../../shared/services/group.service';

import { SelectItem, ConfirmationService, AutoComplete } from 'primeng/primeng';

import { Contact } from '../../shared/models/contact.model';
import { Email } from '../../shared/models/email.model';
import { CommunicationLog } from '../../shared/models/communication-log.model';
import { MessageTemplate } from '../../shared/models/message-template.model';
import { EmailProfile } from '../../shared/models/email-profile.model';
import { Group } from '../../shared/models/group.model';
import { CustomFormBuilder } from '../../shared/classes/CustomFormBuilder';

import { Observable } from 'rxjs/Observable';
import { CommunicationDirection, CommunicationLogTypeNames } from 'app.constant';
import { MessageSignature } from '../../shared/models/message-signature.model';

@Component({
  selector: 'app-message-centre-emails',
  templateUrl: './message-centre-emails.component.html',
  styleUrls: ['./message-centre-emails.component.scss']
})
export class MessageCentreEmailsComponent implements OnInit {
  emailForm: FormGroup;
  isFormInit: boolean = false;

  contactGroupsOptions: SelectItem[] = [];
  fromOptions: SelectItem[] = [];
  toOptions: SelectItem[] = [];

  templateOptions: SelectItem[] = [];
  selectedTemplateOption: string;

  signatureOptions: SelectItem[] = [];
  selectedSignatureOption: string;

  messageMaxLength: number = 720;
  signatureMarker: string = '<p><span style="color: rgb(255, 255, 255);">_</span></p>';



  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;

  constructor(
    private formBuilder: CustomFormBuilder,
    private emailService: EmailService,
    private authService: AuthService,
    private contactService: ContactService,
    private groupService: GroupService,
    private messageTemplateService: MessageTemplateService,
    private messageSignatureService: MessageSignatureService,
    private emailProfileService: EmailProfileService,
    private communicationHistoryService: CommunicationHistoryService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: Contact[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let organization = cT["organization"];

          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.homePhoneNumber ? labelText += " / Tel: " + cT.homePhoneNumber : "";
          cT.mobilePhoneNumber ? labelText += " / Mob: " + cT.mobilePhoneNumber : "";
          cT.email ? labelText += " / Email: " + cT.email : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT
            }
          );
        });

        this.toOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  initForm() {
    this.emailForm = this.formBuilder.group({
      from: this.formBuilder.control(null, [Validators.required]),
      to: this.formBuilder.control(null),
      toGroups: this.formBuilder.control(null),
      subject: this.formBuilder.control(null, [Validators.required]),
      message: this.formBuilder.control(null, [Validators.required])
    });

    this.onGetContactGroups();
    this.onGetFromOptions();
    this.onGetMessageTemplates();
    this.onGetMessageSignatures();

    this.isFormInit = true;
  }

  onGetContactGroups() {
    this.groupService.getGroups().subscribe(
      (groups: Group[]) => {
        groups.map(g => {
          this.contactGroupsOptions.push({ label: g.name, value: g });
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact groups", error);
      }
    );
  }

  onGetFromOptions() {
    this.emailProfileService.getEmailProfiles().subscribe(
      (profiles: EmailProfile[]) => {
        profiles.map(p => {
          this.fromOptions.push({ label: p.name, value: p });
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving email profiles", error);
      }
    );
  }

  onGetMessageTemplates() {
    this.messageTemplateService.getMessageTemplates().subscribe(
      (messageTemplates: MessageTemplate[]) => {
        this.templateOptions = [];

        messageTemplates.map(mT => {
          this.templateOptions.push({ label: mT.name, value: mT });
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving templates", error);
      }
    );
  }

  onGetMessageSignatures() {
    this.messageSignatureService.getMessageSignatures().subscribe(
      (messageSignature: MessageSignature[]) => {
        this.signatureOptions = [];

        messageSignature.map(mT => {
          this.signatureOptions.push({ label: mT.name, value: mT });
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving signatures", error);
      }
    );
  }

  onTemplateChange($event) {
    let selectedValueMessage = $event.value.message;
    let selectedValueSubject = $event.value.name;

    let messageValue = this.emailForm.controls['message'].value;
    let subjectValue = this.emailForm.controls['subject'].value;

    if ((messageValue && messageValue.trim().length > 0) || (subjectValue && subjectValue.trim().length > 0)) {
      this.confirmationService.confirm({
        header: 'Confirm',
        message: 'The template will overwrite your message. Would you like to continue?',
        accept: () => {
          this.emailForm.patchValue({
            message: selectedValueMessage,
            subject: selectedValueSubject
          });
        },
        reject: () => {
          this.selectedTemplateOption = null;
        }
      });
    }
    else {
      this.emailForm.patchValue({
        message: selectedValueMessage,
        subject: selectedValueSubject
      });
    }
  }

  onSignatureChange($event) {
    let selectedValueMessage = $event.value.signiture;

    let messageValue = this.emailForm.controls['message'].value;

    if(messageValue.includes(this.signatureMarker)) // Another signature is already entered
    {

      let messageWithoutSignature = messageValue.substring(0, messageValue.indexOf(this.signatureMarker));
      let messageWithSignatre =  messageWithoutSignature + this.signatureMarker + selectedValueMessage;

      this.confirmationService.confirm({
        header: 'Confirm',
        message: 'Are you sure you want to replace the current signature?',
        accept: () => {
          this.emailForm.patchValue({
            message: messageWithSignatre,
          });
        },
        reject: () => {
          this.selectedSignatureOption = null;
        }
      });
    }
    else {
      let messageWithSignatre = messageValue + this.signatureMarker  + selectedValueMessage;
      this.emailForm.patchValue({
        message: messageWithSignatre
      });
    }
  }

  onSubmit() {
    this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to send this message to the selected contacts?',
      accept: () => {
        let emailMessage = this.formBuilder.sanitizeFormValues(this.emailForm).value;

        let emailProfile = emailMessage.from as EmailProfile;

        let allContactIds = []; // - Ids of contacts that will receive this email

        let toIndividualContactIds = [];
        if (emailMessage.to) {
          // - Get ContactIds from the To Individual field
          toIndividualContactIds = emailMessage.to.map(c => c.value.id) as Array<number>;
        }

        let toGroupContactIds = [];
        if (emailMessage.toGroups) {
          // - Get ContactIds from the To Contact Group field
          toGroupContactIds = emailMessage.toGroups.map(
            c => c.contactGroups.map(cG => cG.contactId) as Array<number>) as Array<Array<number>>;

          // - Get ContactIds from ContactGroup objects for each Group
          toGroupContactIds.map(
            (contactIds) => {
              contactIds.map(cId => allContactIds.push(cId))
            }
          );
        }

        allContactIds = allContactIds.concat(toIndividualContactIds);

        if (allContactIds.length > 0) {
          let emailModel: any = {
            contactIds: allContactIds,
            sentByUserId: this.authService.applicationProfileUser().id,
            body: emailMessage.message,
            subject: emailMessage.subject
          }

          this.onSendEmail(emailModel, emailProfile.id);
        }
        else {
          this.toastService.createWarningMessage("No recipients", "Please select at least one email recipient.");
        }
      }
    });
  }

  onSendAndSave() {
    this.onSubmit();

    let emailMessage = this.formBuilder.sanitizeFormValues(this.emailForm).value;

    emailMessage.message = emailMessage.message.replace(this.signatureMarker,'');

    let generatedName = emailMessage.subject as string;

    let messageTemplate: any = {
      message: emailMessage.message,
      name: generatedName,
      createdByUserAccountId: this.authService.applicationProfileUser().id,
    };

    this.messageTemplateService.addMessageTemplate(messageTemplate).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The template has been created.");

        this.onGetMessageTemplates(); // - Get template options again
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding template", error);
      }
    );
  }

  onSendEmail(mail: Email, profileId: number) {
    this.emailService.sendEmailWithProfile(mail, profileId).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Mailing results:", response["_body"]);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Mailing results:", error);
      }
    );
  }
}
