import { Component, OnInit, ViewChild } from '@angular/core';

import { UserService } from "../../shared/services/user.service";
import { AuthService } from "../../auth/auth.service";
import { OpportunityTypeService } from "../../shared/services/opportunity-type.service";
import { OpportunityLostTypeService } from '../../shared/services/opportunity-lost-type.service';
import { ContactService } from "../../shared/services/contact.service";

import { CrmUser } from "../../shared/models/user.model";
import { OpportunityType } from "../../shared/models/opportunity-type.model";

import { SelectItem, AutoComplete } from "primeng/primeng";
import { Response } from "@angular/http";
import { ApiEndPoints, PrepopulatedOpportunityTypes } from "app.constant";
import { OpportunityLostType } from 'app/views/shared/models/opportunity-lost-type.model';
import { AppSettings } from 'app.settings';
import { Contact } from 'app/views/shared/models/contact.model';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';

@Component({
  selector: 'app-opportunities',
  templateUrl: './opportunities.component.html',
  styleUrls: ['./opportunities.component.scss']
})
export class OpportunitiesComponent implements OnInit {
  users: CrmUser[] = [];               // - Stores all contacts to populate dropdown options
  usersOptions: SelectItem[] = [];  // - Stores Assign User Dropdown Options
  opportunityTypeOptions: SelectItem[] = [];     // - Stores Opportunity Types (Closed, Lost, Open)
  opportunityLostTypeOptions: SelectItem[] = []; // - Stores Opportunity Lost Types
  contactsOptions: SelectItem[] = [];
  selectedUserOption: any;          // - Stores the selected dropdown option which can be 'all' or the id of a contact
  selectedContactOption: number[]= [];
  opportunityTypeId: number = 0;
  opportunityLostTypeId: any;
  fromDate: Date = null;
  toDate: Date = null;
  selectedProbabilityMin:any;
  selectedProbabilityMax:any;
  selectedValueMin:any;
  selectedValueMax:any;

  crmApiEndPoint: string = AppSettings.API_ENDPOINT;

  accessToken = this.authService.currUser().access_token;//localStorage.getItem('accessToken');

  // Datepicker
  dateRange: string = (new Date().getFullYear() - 150) + ':' + new Date().getFullYear(); // - Range: 150 Years Ago until Today

  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;

  constructor(
    public authService: AuthService,
    public opportunityTypeService: OpportunityTypeService,
    public opportunityLostTypeService: OpportunityLostTypeService,
    private userService: UserService,
    private contactService: ContactService,
  ) { }

  ngOnInit() {
    if (this.authService.isAdmin()) {
      this.usersOptions.push({ label: 'All', value: 'all' });
      this.opportunityTypeOptions.push({ label: 'All', value: 'all' });
      this.opportunityLostTypeOptions.push({ label: 'All', value: 'all' });
    }

    this.onGetContacts();
    this.onGetUsers();
    this.onGetOpportunityTypes();
    this.onGetOpportunityLostTypes();
  }


  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }


  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: Contact[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {

          let organization = cT["organization"];

          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.homePhoneNumber ? labelText += " / Tel: " + cT.homePhoneNumber : "";
          cT.mobilePhoneNumber ? labelText += " / Mob: " + cT.mobilePhoneNumber : "";
          cT.email ? labelText += " / Email: " + cT.email : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.contactsOptions = retrievedContactsOptions;
      },
      (error: Response) => {
      }
    );
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Only Admins can get reports about all users
        if (this.authService.isAdmin())
          users.map(u => this.usersOptions.push({ label: u.fullName, value: u.id }));
        else {
          let user = users.find(u => u.id === this.authService.applicationProfileUser().id);
          this.usersOptions.push({ label: user.fullName, value: user.id });
          this.selectedUserOption = user.id;
        }
      }
    );
  }

  onGetContacts() {
    this.contactService.getOpportunityContacts().subscribe(
      (contacts: Contact[]) => {
        contacts.map(c=>this.contactsOptions.push({ label: c.fullName, value: c.id }));
      }
    );
  }

  onGetOpportunityTypes() {
    this.opportunityTypeService.getOpportunityTypes().subscribe(
      (opportunityTypes: OpportunityType[]) => {
        opportunityTypes.map(oT => this.opportunityTypeOptions.push({ label: oT.name, value: oT.id }));
        this.opportunityTypeId = opportunityTypes[0].id; // - First option as default option
      }
    );
  }

  onGetOpportunityLostTypes() {
    this.opportunityLostTypeService.getOpportunityLostTypes().subscribe(
      (opportunityLostTypes: OpportunityLostType[]) => {
        opportunityLostTypes.map(oT => this.opportunityLostTypeOptions.push({ label: oT.name, value: oT.id }));
      }
    );
  }

  isOpportunityTypeLost() {
    const oppTypeIdValue = this.opportunityTypeId;
    const oppType = this.opportunityTypeOptions.find(o => o.value === oppTypeIdValue);

    if (oppType) {
      const oppTypeLabel = oppType.label;
      if (oppTypeLabel === PrepopulatedOpportunityTypes.Lost)
        return true;
    }

    return false;
  }
}
