// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// ng build -c=production` then `environment.prod.ts` will be used instead.



export const environment = {
  production: true,
  PORTAL_URI: `https://crm.reapcrm.com/`, 

  API_ENDPOINT: 'https://crm-api.reapcrm.com/',

  AUTHORITY_ENDPOINT: 'https://crm-auth.reapcrm.com',
  POST_LOGOUT_REDIRECT_URI: 'https://crm.reapcrm.com',
  REDIRECT_URI: 'https://crm.reapcrm.com/auth',
  SILENT_REDIRECT_URI: 'https://crm.reapcrm.com/silent'
};
