import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager, PrepopulatedEntities, PrepopulatedContactTypes, PrepopulatedContactSources, PrepopulatedOpportunityTypes } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { OpportunityService } from "../../shared/services/opportunity.service";
import { OpportunityTypeService } from "../../shared/services/opportunity-type.service";
import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { UserService } from "../../shared/services/user.service";
import { ContactService } from "../../shared/services/contact.service";
import { OpportunityLostTypeService } from '../../shared/services/opportunity-lost-type.service';
import { ProductService } from '../../shared/services/product.service';
import { QuoteService } from '../../shared/services/quote.service';
import { Opportunity } from "../../shared/models/opportunity.model";
import { OpportunityType } from "../../shared/models/opportunity-type.model";

import { SelectItem, ConfirmationService, AutoComplete } from "primeng/primeng";
import { Observable } from "rxjs/Rx";
import { CrmUser } from "../../shared/models/user.model";
import { Contact } from "../../shared/models/contact.model";
import { OpportunityLostType } from '../../shared/models/opportunity-lost-type.model';
import { Product } from '../../shared/models/product.model';
import { Quote } from 'app/views/shared/models/quote.model';
import { Task } from 'app/views/shared/models/task.model';
import { TaskService } from 'app/views/shared/services/task.service';
import { Organization } from 'app/views/shared/models/organization.model';

@Component({
  selector: 'app-opportunity-manage',
  templateUrl: './opportunity-manage.component.html',
  styleUrls: ['./opportunity-manage.component.scss']
})
export class OpportunityManageComponent implements OnInit {
  opportunityForm: FormGroup;                     // - Stores the opportunity form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update an opportunity if true and add a new opportunity if false
  isQuoteClosed: boolean = false;                 // - If Quote was selected and now cannot add more quotes

  opportunity: Opportunity = new Opportunity();   // - Stores Opportunity to populate form with a new / existing opportunity's values

  productsOptions: SelectItem[] = [];             // - Stores Products Dropdown Options
  opportunityTypesOptions: SelectItem[] = [];     // - Stores Opportunity Type Dropdown Options
  opportunityLostTypesOptions: SelectItem[] = []; // - Stores Opportunity Lost Type Dropdown Options
  assignedUserOptions: SelectItem[] = [];         // - Stores Users to select the Assigned User of this opportunity
  assignedContactOptions: SelectItem[] = [];      // - Stores Contacts to select the Assigned Contact of this opportunity
  tasks: Task[] = [];                             // - List of tasks displayed in table

  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private contactService: ContactService,
    private productService: ProductService,
    private taskService: TaskService,
    private opportunityService: OpportunityService,
    private quoteService: QuoteService,
    private opportunityTypeService: OpportunityTypeService,
    private opportunityLostTypeService: OpportunityLostTypeService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const opportunityId = params['id'];
        this.isEdit = opportunityId != null; // - Returns true if an id was present within the url

        if (this.isEdit)
          this.onGetOpportunity(opportunityId);
        else
          this.initForm();
      }
    );
  }

  initForm() {
    this.opportunityForm = this.formBuilder.group({
      name: this.formBuilder.control(this.opportunity.name, [Validators.required]),
      description: this.formBuilder.control(this.opportunity.description, [Validators.required]),
      productIds: this.formBuilder.control(this.opportunity.opportunityProducts.map(oP => oP.productId)),
      probability: this.formBuilder.control(this.opportunity.probability, [Validators.min(0), Validators.max(100)]),
      value: this.formBuilder.control(this.opportunity.value),
      assignedContactId: this.formBuilder.control(this.opportunity.assignedContactId, [Validators.required]),
      assignedUserAccountId: this.formBuilder.control(this.opportunity.assignedUserAccountId),
      opportunityTypeId: this.formBuilder.control(this.opportunity.opportunityTypeId, [Validators.required]),
      opportunityLostTypeId: this.formBuilder.control(this.opportunity.opportunityLostTypeId),
      lostTypeDescription: this.formBuilder.control(this.opportunity.lostTypeDescription)
    });

    this.onGetOpportunityTypes();
    this.onGetOpportunityLostTypes();
    this.onGetUsers();
    this.onGetProducts();

    if (this.isEdit && this.opportunity.assignedContact) {
      let c = this.opportunity.assignedContact;
      let labelText = c.fullName.trim().length > 0 ? c.fullName : "NO-NAME";
      c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
      c.companyName ? labelText += " / Company: " + c.companyName : "";
      c.homePhoneNumber ? labelText += " / Tel: " + c.homePhoneNumber : "";
      c.mobilePhoneNumber ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
      c.email ? labelText += " / Email: " + c.email : "";

      let defaultSelectItem: SelectItem = {
        label: labelText,
        value: this.opportunity.assignedContactId
      };

      let defaultValue = this.opportunity.assignedContactId ? defaultSelectItem : null;

      this.opportunityForm.patchValue({
        assignedContactId: defaultValue
      });

      this.onGetQuotes(this.opportunity.id);
      this.onGetTasks(this.opportunity.id);
    }

    this.route.queryParams.subscribe(
      (queryParam: Params) => {
        let contactId = queryParam['contact'];
        if (contactId != null) {

          this.contactService.getContact(+contactId).subscribe(
            (contact: Contact) => {
              let defaultValue: SelectItem = {
                label: contact.fullName,
                value: +contactId
              };

              this.opportunityForm.patchValue({
                assignedContactId: defaultValue
              });
            }
          );
        }
      }
    );

    this.isFormInit = true;
  }

  isOpportunityTypeLost() {
    const oppTypeIdValue = this.opportunityForm.get('opportunityTypeId').value;
    const oppType = this.opportunityTypesOptions.find(o => o.value === oppTypeIdValue);

    if (oppType) {
      const oppTypeLabel = oppType.label;
      if (oppTypeLabel === PrepopulatedOpportunityTypes.Lost)
        return true;
    }

    return false;
  }

  onGetProducts() {
    this.productService.getProducts().subscribe(
      (products: Product[]) => {
        // - Store the retrieved products as options for multiselect
        products.map(p => {
          this.productsOptions.push({ label: p.name, value: p.id });
        });

        if (this.isEdit) {
          this.opportunityForm.patchValue({
            productIds: this.opportunity.opportunityProducts.map(oP => oP.productId)
          });
        }
      }
    );
  }


  onGetQuotes(opportunityId: number){
    this.quoteService.getQuotesByOpportunityId(opportunityId).subscribe(
      (quotes: Quote[]) => {
        this.opportunity.quotes = quotes;
        quotes.forEach(element => {
          if(element.isSelectedInvoice){
            this.isQuoteClosed = true;
          }
        });
      }
    );
  }


  //-------- TASKS --------
  onGetTasks(opportunityId: number) {
    // Admins can see everyone's tasks
    // if (this.authService.isAdmin()) {
      this.taskService.getTasksByOpportunityId(opportunityId).subscribe(
        (tasks: Task[]) => {
          this.opportunity.tasks = tasks;
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving tasks", error);
        }
      );
    // }
  }

  onCompleteTask(task: Task) {
    let completedTask = this.opportunity.tasks.find(t => t.id === task.id);
    task.updatedByUserAccountId = this.authService.applicationProfileUser().id; // Actual task

    this.taskService.toggleTaskCompletion(task).subscribe(
      (response: Response) => {
        // NOTE: The update is made in the API!!!
        // Here I am setting the task to complete because the update was successful, so we want the change to be shown to the user
        completedTask.isTaskComplete = !completedTask.isTaskComplete;
        this.taskService.taskCounterChanged.next();
        this.taskService.tasksChanged.next();

        // Only ask for new comm. log if task is complete and is assigned to at least one contact
        if (task.contactTasks.length > 0 && completedTask.isTaskComplete)
          this.onAskForCommunicationLog(completedTask);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error completing task", error);
      }
    );
  }

  onAskForCommunicationLog(task: Task) {
    let firstContact = task.contactTasks[0].contact;

    this.confirmationService.confirm({
      header: 'Communication Log',
      message: 'The task has been marked as complete. Would you now like to create a new communication log for ' + firstContact.fullName + '?',
      accept: () => {
        this.router.navigate(['/contacts', firstContact.id, 'communication-history', 'new']);
      }
    });
  }

  rowStyle(task: Task, rowIndex: number) {
    if (task.isTaskComplete)
      return "bg-success";

    // Is Task Late?
    let reminderDate1 = new Date(task.reminderDate);
    let now = new Date();

    // If the task is not completed and the reminder date has already passed
    if (!task.isTaskComplete && now > reminderDate1)
      return "bg-danger";

    // Is Task For Today?
    let reminderDate = new Date(task.reminderDate).setHours(0, 0, 0, 0);
    let todayDate = new Date().setHours(0, 0, 0, 0);

    let reminderDateTime = new Date(task.reminderDate);
    let todayDateTime = new Date();

    // If the task is not completed and the reminder date is set for today (but time has not yet passed)
    if (!task.isTaskComplete && (todayDate === reminderDate && todayDateTime <= reminderDateTime))
      return "bg-warning";

    return "";
  }

  onDeleteTask(task: Task) {
    this.confirmationService.confirm({
      header: 'Delete Task',
      message: 'Are you sure you want to delete this task?',
      accept: () => {
        task.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.taskService.deleteTask(task).subscribe(
          (response: Response) => {
            this.onGetTasks(this.opportunity.id);
            // this.taskService.tasksChanged.next();
            this.taskService.taskCounterChanged.next();
            this.toastService.createSuccessMessage("Success", "The task has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting task", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for the task has been aborted.");
      }
    });
  }


  //-------- END TASKS --------

  onGetOpportunity(id: number) {
    this.opportunityService.getOpportunity(id).subscribe(
      (opportunity: Opportunity) => {
        this.opportunity = opportunity;
        this.initForm();
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunity", error);
        this.router.navigate(['/error/404']);
      }
    );
  }

  onGetOpportunityTypes() {
    this.opportunityTypeService.getOpportunityTypes().subscribe(
      (opportunityTypes: OpportunityType[]) => {
        // Store retrieved opportunity types in the opportunityTypesOptions select list
        opportunityTypes.map(cT => this.opportunityTypesOptions.push({ label: cT.name, value: cT.id }));

        let defaultValue = 0;

        if (!this.isEdit)
          defaultValue = this.opportunityTypesOptions[0].value;
        else
          defaultValue = this.opportunity.opportunityType.id;

        this.opportunityForm.patchValue({
          opportunityTypeId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunity types", error);
      }
    );
  }

  onGetOpportunityLostTypes() {
    this.opportunityLostTypeService.getOpportunityLostTypes().subscribe(
      (opportunityLostTypes: OpportunityLostType[]) => {
        // Store retrieved opportunity types in the opportunityLostTypesOptions select list
        opportunityLostTypes.map(cT => this.opportunityLostTypesOptions.push({ label: cT.name, value: cT.id }));

        let defaultValue = null;

        if (this.isEdit)
          defaultValue = this.opportunity.opportunityLostType ? this.opportunity.opportunityLostTypeId : null;

        this.opportunityForm.patchValue({
          opportunityLostTypeId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lost opportunity types", error);
      }
    );
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Store retrieved users in the usersOptions select list
        users.map(u => this.assignedUserOptions.push({ label: u.fullName, value: u.id }));

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.authService.applicationProfileUser().id;
        else
          defaultValue = this.opportunity.assignedUserAccountId ? this.opportunity.assignedUserAccountId : this.authService.applicationProfileUser().id;

        this.opportunityForm.patchValue({
          assignedUserAccountId: defaultValue
        });
      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: Contact[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let organization = cT["organization"];

          let labelText =
          cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          organization ?  organization.name ? labelText += " / Company: " + organization.name : "" : "";
          cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.homePhoneNumber ? labelText += " / Tel: " + cT.homePhoneNumber : "";
          cT.mobilePhoneNumber ? labelText += " / Mob: " + cT.mobilePhoneNumber : "";
          cT.email ? labelText += " / Email: " + cT.email : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.assignedContactOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onAddOpportunity(newOpportunity: Opportunity) {
    this.opportunityService.addOpportunity(newOpportunity).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The opportunity " + newOpportunity.name + " has been created.");

        this.confirmationService.confirm({
          header: 'New Quote',
          message: 'Would you like to create a quote for this opportunity?',
          accept: () => {
            this.router.navigate(['/opportunities','quote',(<any>response).id, 'new']);
          },
          reject: () => {
            this.router.navigate(['/opportunities/']);
          }
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding opportunity", error);
      }
    );
  }

  onUpdateOpportunity(newOpportunity: Opportunity) {
    this.opportunityService.updateOpportunity(newOpportunity).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The opportunity " + newOpportunity.name + " has been updated.");
        this.router.navigate(['/opportunities/']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating opportunity", error);
      }
    );
  }

  onSubmit() {
    let newOpportunity = this.formBuilder.sanitizeFormValues(this.opportunityForm).value;

    // This is to pass correct value (contact id) from the autocomplete selection and to sanitize it
    // (Eg: writing random string in search and submit form would return an api error response)
    if (newOpportunity.assignedContactId && newOpportunity.assignedContactId.value)
      newOpportunity.assignedContactId = newOpportunity.assignedContactId.value;
    else
      newOpportunity.assignedContactId = null;

    newOpportunity.opportunityProducts = this.populateOpportunityProducts(newOpportunity.productIds);

    if (this.isEdit) {
      newOpportunity.id = this.opportunity.id; // - Set id of edited opportunity to its original id, same with externalId and createdById
      newOpportunity.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateOpportunity(newOpportunity);
    }
    else {
      newOpportunity.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddOpportunity(newOpportunity);
    }
  }

  populateOpportunityProducts(assignedProductIds: number[]) {
    let opportunityProducts: {}[] = [];

    for (let id of assignedProductIds) {
      let opportunityProduct = {
        productId: id,
        opportunityId: this.opportunity.id,
        createdByUserAccountId: this.authService.applicationProfileUser().id,
      };
      opportunityProducts.push(opportunityProduct);
    }

    return opportunityProducts;
  }


  onRowRender(rowData){
    let rowFormGroup = rowData as Quote;
    if(rowFormGroup.isSelectedInvoice == true) return 'isSelectedInvoice';
    else return '';
  }
}
